
import AvatarImage from "@/components/AvatarImage.vue";
import { UsersModule } from "@/store/modules/users";
import { PopoutsModule } from "@/store/modules/popouts";
import { kickMember, banMember } from "@/services/serverService";
import User from "@/interfaces/User";
import CustomButton from "@/components/CustomButton.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { AvatarImage, CustomButton },
  props: {
    data: {
      type: Object as PropType<{
        id: string;
        serverID: string;
        action: string;
        tempUser: User;
      }>,
      required: true,
    },
  },
  data() {
    return {
      requestSent: false,
    };
  },
  computed: {
    user(): any {
      return UsersModule.users[this.data.id] || this.data.tempUser || {};
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("ban-or-kick-user-popout");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    buttonClicked() {
      if (this.requestSent) return;
      this.requestSent = true;
      const fun = this.data.action === "BAN" ? banMember : kickMember;
      fun(this.data.serverID, this.data.id)
        .then(() => {
          this.close();
        })
        .catch(() => {
          this.requestSent = false;
        });
    },
  },
});
