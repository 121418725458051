
import { seededColor } from "seeded-color";
import { useWindowProperties } from "@/utils/windowProperties";
import { highPriorityBadge } from "@/constants/badges";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  props: {
    size: {
      type: String,
      required: false,
    },
    imageId: {
      type: String as PropType<string | null | undefined>,
      required: false,
    },
    seedId: {
      type: String,
      required: false,
    },
    willHaveClickEvent: {
      type: Boolean,
      required: false,
    },
    animateGif: {
      type: Boolean,
      required: false,
    },
    customUrl: {
      type: String,
      required: false,
    },
    badges: {
      type: Number,
      required: false,
    },
  },
  computed: {
    src(): any {
      let url = process.env.VUE_APP_NERTIVIA_CDN + (this.imageId as string);
      if (!this.isGif) return url;
      if (!this.animateGif || !this.isFocused) {
        url += "?type=webp";
      }
      return url;
    },
    highPriorityBadge(): any {
      if (!this.badges) return null;
      return highPriorityBadge(this.badges);
    },
    isFocused(): any {
      return useWindowProperties().isFocused;
    },
    isGif(): any {
      return (this.imageId as string).endsWith(".gif");
    },
    bgColor(): any {
      if (this.imageId) return null;
      if (!this.seedId) return null;
      return seededColor(this.seedId);
    },
    style(): any {
      const style: any = {
        width: this.size,
        height: this.size,
      };

      if (this.highPriorityBadge) {
        style.borderColor = this.highPriorityBadge.color;
      }
      return style;
    },
  },
});
